import { useCallback, useEffect, useState } from "react";
import {} from "../../globals/style";
import axios from "axios";
import { APP_URL } from "../../config";

import {
  FormContainer,
  FormInput,
  FormTitle,
  FormButton,
} from "../../components/Form";

import {
  LoginContainer,
  MainContainer,
  PhotoContainer,
  Disclaimer,
  DisclaimerLink,
} from "./styles";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router";
import { Loading, Notify } from "notiflix";
import { useSearchParams } from "react-router-dom";

const Token = () => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token")) {
      setToken(searchParams.get("token"));
    }
  }, []);

  function onTokenChange(e) {
    setToken(e.target.value);
  }

  function onPassChange(e) {
    setPassword(e.target.value);
  }

  function onPassConfirmationChange(e) {
    setPasswordConfirmation(e.target.value);
  }

  function FormSubmit(e) {
    e.preventDefault();
    Loading.circle();
    axios
      .post(
        APP_URL + "/auth/token/process",
        {
          token: token,
          password: password,
          password_confirmation: passwordConfirmation,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        Loading.remove();
        Notify.success(
          "Senha definida com sucesso, agora você pode fazer login."
        );
        navigate("/");
      })
      .catch(function (error) {
        Loading.remove();
        if (error.response.data.errors["token"])
          return Notify.failure("Token inválido.");
        if (error.response.data.errors["password"])
          return Notify.failure("As senhas não coincidem.");
      });
  }

  return (
    <MainContainer>
      <LoginContainer>
        <FormContainer onSubmit={FormSubmit}>
          <Logo width="200" />
          <FormTitle>Recupere a senha da sua conta</FormTitle>
          <FormInput
            name="token"
            type="text"
            placeholder="Token de recuperação"
            value={token ? token : ""}
            onChange={onTokenChange}
            required
          />

          <FormInput
            name="password"
            type="password"
            placeholder="Senha"
            value={password ? password : ""}
            onChange={onPassChange}
            required
          />

          <FormInput
            name="password_confirmation"
            type="password"
            placeholder="Confirmação de Senha"
            value={passwordConfirmation ? passwordConfirmation : ""}
            onChange={onPassConfirmationChange}
            required
          />

          <FormButton>Alterar senha</FormButton>
          <Disclaimer>
            Ainda não tem uma conta?
            <DisclaimerLink to={"https://quiz.matchyia.com/quiz2"}>
              {" "}
              Clique aqui e adquira.
            </DisclaimerLink>
          </Disclaimer>
        </FormContainer>
      </LoginContainer>
      <PhotoContainer></PhotoContainer>
    </MainContainer>
  );
};

export default Token;
