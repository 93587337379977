import { useEffect, useState } from "react";
import {} from "./styles";
import logoMatchyia from "../../../assets/logo.png";
import { DashboardContainer } from "../../DashboardIndex/styles";
import {
  FormButton,
  FormContainer,
  FormInput,
  FormSelect,
  FormTitle,
} from "../../../components/Form";
import { Disclaimer } from "../../Login/styles";
import {
  ActiveBadge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../globals/tables";
import axios from "axios";
import { APP_URL } from "../../../config";
import { Form, Link, useNavigate } from "react-router-dom";
import { Notify } from "notiflix";

const AdminCreateUser = () => {
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];
    axios
      .get(APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log("a");
        let meObject = response.data;
        if (meObject.email != "admin@matchyia.com") {
          navigate("/");
        }
      });
  });

  function handleSubmit(e) {
    e.preventDefault();
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .post(
        APP_URL + "/admin/users",
        {
          first_name: name,
          email: email,
          active: active,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(async (response) => {
        let user = response.data.user;
        Notify.success(
          "Usuário <b>" + user.first_name + "</b> criado com sucesso."
        );
        navigate("/admin");
      })
      .catch(async (err) => {
        Notify.failure("Erro ao criar o usuário, contate o Gustavo k");
      });
  }
  return (
    <div
      id="dashboard"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <DashboardContainer style={{ width: "70%" }}>
        <img
          src={logoMatchyia}
          width={"200"}
          style={{
            marginTop: "0",
            marginBottom: "50px",
          }}
        />
        <FormTitle>
          Olá, <b>{user.first_name ? user.first_name : "Administrador"}</b>
        </FormTitle>
        <Disclaimer style={{ marginTop: "-10px" }}>
          Crie um usuário digitando os dados abaixo.
        </Disclaimer>
        <FormContainer
          onSubmit={handleSubmit}
          style={{ margin: 0, padding: 0, alignItems: "center" }}
        >
          <FormInput
            placeholder="Digite o nome do usuário. Ex.: Pedro Velleda"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <FormInput
            placeholder="Digite o email do usuário. Ex.: pedro@gmail.com"
            value={email}
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormSelect
            value={active}
            onChange={(e) => {
              setActive(e.target.value);
            }}
          >
            <option value={true}>Ativo</option>
            <option false={false}>Inativo</option>
          </FormSelect>
          <FormButton style={{ width: "100%" }}>Criar usuário</FormButton>
        </FormContainer>
      </DashboardContainer>
    </div>
  );
};

export default AdminCreateUser;
