import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import DashboardIndex from "./pages/DashboardIndex";
import ProtectedRoute from "./utils/ProtectedRoute";
import AdminIndex from "./pages/Admin/AdminIndex";
import AdminCreateUser from "./pages/Admin/AdminCreateUser";
import AdminLogin from "./pages/AdminLogin";
import Token from "./pages/Token";
import Reset from "./pages/Reset";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardIndex />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<Reset />} />
        <Route path="/token" element={<Token />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/create"
          element={
            <ProtectedRoute>
              <AdminCreateUser />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
