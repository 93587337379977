import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "./modal.css";
import { Loading, Notify } from "notiflix";
import { useNavigate } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import {
  FormButton,
  FormContainer,
  FormInput,
  FormSelect,
  FormTitle,
} from "../../../components/Form";
import { Disclaimer } from "../../../pages/Login/styles";
const CreateLeadModal = (props) => {
  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      height: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
    },
  };

  const [form, setForm] = React.useState({});
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [active, setActive] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  Modal.setAppElement("#root");

  return (
    <>
      <Modal
        class={"modal"}
        isOpen={props.active}
        onRequestClose={() => {
          props.setActive(false);
        }}
        contentLabel="Example Modal"
        onAfterOpen={() => {
          setForm({});
        }}
        style={modalStyle}
        closeTimeoutMS={500}
      >
        <FormTitle>
          Editando usuário:{" "}
          <b>{user.first_name ? user.first_name : "Usuário"}</b>
        </FormTitle>
        <Disclaimer style={{ marginTop: "-10px" }}>
          Edite o usuário <b>{user.first_name ? user.first_name : "ERRO"}</b>{" "}
          abaixo.
        </Disclaimer>
        <FormContainer style={{ margin: 0, padding: 0, alignItems: "center" }}>
          <FormInput
            placeholder="Digite o nome do usuário. Ex.: Pedro Velleda"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <FormInput
            placeholder="Digite o email do usuário. Ex.: pedro@gmail.com"
            value={email}
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormInput
            placeholder="Digite o número de telefone do usuário"
            value={phone}
            type="phone"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <FormSelect
            value={active}
            onChange={(e) => {
              setActive(e.target.value);
            }}
          >
            <option value={true}>Ativo</option>
            <option false={false}>Inativo</option>
          </FormSelect>
          <FormButton style={{ width: "100%" }}>Criar usuário</FormButton>
        </FormContainer>
      </Modal>
    </>
  );
};

export default CreateLeadModal;
