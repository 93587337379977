import Logo from "../../components/Logo";
import {
  FormButton,
  FormContainer,
  FormInput,
  FormSelect,
  FormTitle,
} from "../../components/Form";
import { DashboardContainer } from "./styles";
import logoMatchyia from "../../assets/logo.png";
import { InputMask } from "react-input-mask";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../config";
import { Disclaimer, DisclaimerLink } from "../Login/styles";
import { Notify } from "notiflix";
import { Link } from "react-router-dom";

const DashboardIndex = () => {
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState(0);
  const [user, setUser] = useState({});

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .get(APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let meObject = response.data;
        setPhone(meObject.phone ? maskNumber(meObject.phone) : "");
        setGender(meObject.gender ? meObject.gender : "0");
        setUser(meObject);
      });
  }, []);

  function maskNumber(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4,5})(\d{4})/, "$1-$2");
  }

  function onPhoneChange(e) {
    setPhone(maskNumber(e.target.value));
  }

  function onGenderChange(e) {
    setGender(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    if (gender == 0) return Notify.failure("Selecione seu gênero.");
    let newPhone = phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");

    if (newPhone.length < 11) {
      return Notify.failure(
        "Seu número não pode ter menos de 11 digitos. Não esqueça o 9 inicial."
      );
    }

    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .put(
        APP_URL + "/phone/",
        {
          phone: newPhone,
          gender: gender,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setUser(res.data);
        Notify.success("Número de telefone vinculado com sucesso.");
      })
      .catch((res) => {
        Notify.failure("Você já tem o número de telefone vinculado.");
      });
  }

  return (
    <div
      id="dashboard"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <DashboardContainer>
        <img
          src={logoMatchyia}
          width={200}
          style={{
            marginTop: "50px",
            marginBottom: "50px",
          }}
        />
        <FormContainer
          style={{ width: "80%", padding: "0" }}
          onSubmit={onSubmit}
        >
          <FormTitle>
            Seja bem-vindo, <b>{user.first_name}</b>
          </FormTitle>
          <Disclaimer>
            Digite seu número do WhatsApp para fazer a liberação do bot.
          </Disclaimer>
          <FormSelect
            value={gender}
            onChange={onGenderChange}
            required
            disabled={user.gender ? true : false}
          >
            <option value={0} disabled>
              Selecione seu gênero
            </option>
            <option value={1}>Masculino</option>
            <option value={2}>Feminino</option>
            <option value={3}>Outro</option>
            <option value={4}>Prefiro não dizer</option>
          </FormSelect>
          <FormInput
            value={phone}
            onChange={onPhoneChange}
            placeholder="(00) 00000-0000"
            maxLength={15}
            disabled={user.phone ? true : false}
            required
          />
          {user.phone ? (
            <>
              <Link
                to={
                  "https://api.whatsapp.com/send/?phone=5551990127486&text=Quero%20acessar%20o%20MatchyIA."
                }
              >
                <FormButton type="button" style={{ width: "100%" }}>
                  Clique aqui para iniciar uma conversa
                </FormButton>
              </Link>
              <Disclaimer>
                Por algum motivo, deseja alterar seu WhatsApp?{" "}
                <DisclaimerLink to={"#"}>
                  Fale com nosso suporte no WhatsApp.
                </DisclaimerLink>
              </Disclaimer>
            </>
          ) : (
            <>
              <FormButton style={{ width: "100%" }}>Salvar</FormButton>
              <Disclaimer>
                Precisa falar com nosso suporte?{" "}
                <DisclaimerLink to={"https://matchyia.com/suporte"}>
                  Clique aqui
                </DisclaimerLink>
              </Disclaimer>
            </>
          )}
        </FormContainer>
      </DashboardContainer>
    </div>
  );
};

export default DashboardIndex;
