import { useCallback, useEffect, useState } from "react";
import {} from "../../globals/style";
import axios from "axios";
import { APP_URL } from "../../config";

import {
  FormContainer,
  FormInput,
  FormTitle,
  FormButton,
} from "../../components/Form";

import {
  LoginContainer,
  MainContainer,
  PhotoContainer,
  Disclaimer,
  DisclaimerLink,
} from "./styles";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router";
import { Loading, Notify } from "notiflix";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function FormSubmit(e) {
    e.preventDefault();
    Loading.circle();
    axios
      .post(
        APP_URL + "/auth/token",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        Loading.remove();
        Notify.success(
          "Caso o e-mail exista, enviaremos um link para a sua conta."
        );
        navigate("/token");
      });
  }

  return (
    <MainContainer>
      <LoginContainer>
        <FormContainer onSubmit={FormSubmit}>
          <Logo width="200" />
          <FormTitle>Recupere a senha da sua conta</FormTitle>
          <FormInput
            name="email"
            type="email"
            placeholder="Endereço de e-mail"
            value={email ? email : ""}
            onChange={onEmailChange}
            required
          />
          <FormButton>Enviar link de recuperação</FormButton>
          <Disclaimer>
            Ainda não tem uma contaa?
            <DisclaimerLink to={"https://quiz.matchyia.com/quiz2"}>
              {" "}
              Clique aqui e adquira.
            </DisclaimerLink>
          </Disclaimer>
        </FormContainer>
      </LoginContainer>
      <PhotoContainer></PhotoContainer>
    </MainContainer>
  );
};

export default PasswordReset;
