import { Navigate } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_URL } from "../../config";
import { Notify } from "notiflix";

const ProtectedRoute = ({ children }) => {
  let token = document?.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];

  let location = useLocation();
  let navigate = useNavigate();

  return token ? children : <Navigate to={"/login"} />;
};

export default ProtectedRoute;
