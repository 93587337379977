import { useEffect, useState } from "react";
import {} from "./styles";
import logoMatchyia from "../../../assets/logo.png";
import { DashboardContainer } from "../../DashboardIndex/styles";
import { FormButton, FormTitle } from "../../../components/Form";
import { Disclaimer } from "../../Login/styles";
import {
  ActiveBadge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../globals/tables";
import axios from "axios";
import { APP_URL } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import EditUserModal from "../../../components/modals/EditUserModal";

const AdminIndex = () => {
  function createUser(id, name, email, active, phone) {
    return {
      id,
      name,
      email,
      active,
      phone,
    };
  }
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState([]);
  const [count, setCount] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [edit, setEdit] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = document?.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1];

    axios
      .get(APP_URL + "/auth/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log("a");
        let meObject = response.data;
        setUser(meObject);
      });
    axios
      .get(APP_URL + "/admin/users", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(async (response) => {
        let users = response.data.users;
        setCount(response.data.count);
        setActive(response.data.active);
        let usrArray = [];
        await users.forEach((user) => {
          usrArray.push(
            createUser(
              user.id,
              user.first_name,
              user.email,
              user.active,
              user.phone
            )
          );
        });

        setUsers(usrArray);
        console.log(users);
      })
      .catch((err) => {
        navigate("/");
      });
  }, []);

  return (
    <div
      id="dashboard"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <EditUserModal
        active={activeModal}
        id={edit}
        setActive={setActiveModal}
      />
      <DashboardContainer
        style={{
          width: "70%",
          maxHeight: "80%",
          overflow: "auto",
          justifyContent: "start",
        }}
      >
        <img
          src={logoMatchyia}
          width={"200"}
          style={{
            marginTop: "50px",
            marginBottom: "50px",
          }}
        />
        <FormTitle>
          Olá, <b>{user.first_name ? user.first_name : ""}</b>
        </FormTitle>
        <Disclaimer style={{ marginTop: "-10px" }}>
          Seja bem-vindo ao painel <b>Admin</b>.
        </Disclaimer>
        <Link to={"/admin/create"}>
          <Disclaimer>Criar nova conta</Disclaimer>
        </Link>
        <Table style={{ marginTop: "50px", width: "80%" }}>
          <TableHead>
            <TableHeader>Usuários Cadastrados</TableHeader>
            <TableHeader>Usuários Ativos</TableHeader>
            <TableHeader>Conversas Criadas</TableHeader>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{count}</TableCell>
              <TableCell>{active}</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table style={{ marginTop: "50px", width: "80%" }}>
          <TableHead>
            <TableRow>
              <TableHeader>Nome</TableHeader>
              <TableHeader>E-mail</TableHeader>
              <TableHeader>Ativo</TableHeader>
              <TableHeader>Telefone</TableHeader>
              <TableHeader>Ações</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow>
                <TableCell component={"th"} align="left">
                  {row.name}
                </TableCell>
                <TableCell component={"th"} align="left">
                  {row.email}
                </TableCell>
                <TableCell component={"th"} align="left">
                  <ActiveBadge active={row.active ? true : false}>
                    {row.active ? "Ativo" : "Inativo"}
                  </ActiveBadge>
                </TableCell>
                <TableCell component={"th"} align="left">
                  {row.phone ? row.phone : "-"}
                </TableCell>
                <TableCell component={"th"} align="left">
                  <button
                    onClick={() => {
                      setEdit(row.id);
                      setActiveModal(true);
                    }}
                  >
                    Editara
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DashboardContainer>
    </div>
  );
};

export default AdminIndex;
