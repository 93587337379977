import styled from "styled-components";

export const FormContainer = styled.form`
  height: 30%;
  width: 60%;
  border-radius: 15px;
  padding-left: 15%;
  transition: all 0.5s;
  @media only screen and (max-width: 768px) {
    width: 80%;
    padding: 40px;
  }

  &:hover {
    border-color: darkgray;
  }
`;

export const FormInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding-left: 10px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
`;

export const FormSelect = styled.select`
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  padding-left: 10px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
`;

export const FormTitle = styled.h1`
  color: black;
  font-weight: 500;
  font-size: 20px;
`;

export const FormButton = styled.button`
  margin-top: 20px;
  height: 50px;
  width: 100%;
  border: none;
  background-image: linear-gradient(to right, #ea426e, #ee7ea1);
  color: white;
  font-size: 18px;
  font-weight: 600;
`;
